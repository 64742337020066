import React, { useEffect } from "react"
// import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { useLocation } from "@reach/router"
import "normalize.css"
import "../assets/css/main.css"

const Layout = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    // Run this code when the component mounts (i.e., when the page loads)
    const element = document.getElementById("animate")
    if (element) {
      // Check if the element exists
      // Determine the page path
      const currentPage = location.pathname
      // Specify the page path where you want to add the CSS class
      const specificPagePath = "/"
      // Conditionally add or remove the CSS class based on the page path
      if (currentPage === specificPagePath) {
        element.classList.add("logo-animate")
      } else {
        element.classList.remove("logo-animate")
      }
    }
  }, [location.pathname]) // Run effect when the pathname changes

  return (
    <>
      <Navbar />
      {children}

      <Footer />
    </>
  )
}

export default Layout
