import React from "react"
import { FaReact } from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="page-footer">
      <p>
        &copy; {new Date().getFullYear()} shaunvine.com - Built with
        <a
          aria-label="Gatsby"
          className="gatsby"
          href="https://www.gatsbyjs.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Gatsby</span>
        </a>
        and
        <a
          aria-label="React"
          className="react"
          href="https://react.dev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaReact />
        </a>
      </p>
    </footer>
  )
}

export default Footer
