// SEO set up with Gatsby plugin react helmet: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=gatsby%20plugin%20react%20hel

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteDescription
      }
    }
  }
`

const SEO = ({ title, siteDescription }) => {
  const { site } = useStaticQuery(query)
  const metaDescription = siteDescription || site.siteMetadata.siteDescription
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} | ${site.siteMetadata.title}`}
      meta={[{ name: `siteDescription`, content: metaDescription }]}
    ></Helmet>
  )
}

export default SEO
